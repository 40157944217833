import { styled } from "@mui/material/styles";

type SwiperWrapperStylesPropsType = {
  isNavigationVisible?: boolean;
  isNavigationFixed?: boolean;
};
export const SwiperWrapperStyles = styled("div")<SwiperWrapperStylesPropsType>(({ theme, ...props }) => ({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  position: "relative",
  ".swiper": {
    ...(props.isNavigationFixed &&
      props.isNavigationVisible && {
        "&:before": {
          content: '""',
          position: "fixed",
          bottom: 0,
          height: 180,
          background: "linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent)",
          left: 0,
          right: 0,
          zIndex: 10,
        },
      }),
  },
  ".swiper-pagination-bullets": {
    bottom: "30px !important",
    opacity: props.isNavigationVisible ? 1 : 0,
    zIndex: 11,
  },
  ".swiper-pagination-bullet": {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.6,
    width: 16,
    height: 16,
    marginBottom: "8px !important",
  },
  ".swiper-pagination-bullet-active-main": {
    backgroundColor: theme.palette.background.paper,
    opacity: 1,
  },
  ".swiper-button-next, .swiper-button-prev": {
    backgroundColor: theme.palette.white["024"],
    color: theme.palette.white["100"],
    width: 85,
    height: 85,
    borderRadius: 50,
    boxShadow: theme.shadows[20],
    opacity: props.isNavigationVisible ? 1 : 0,
    transition: "0.2s",

    ...(theme.breakpoints.down("md") && {
      height: 55,
      width: 55,
      display: "none",
    }),

    "&:after": {
      fontSize: 24,
    },
    "&:hover": {
      transform: "scale(0.9)",
    },
  },
  ".swiper-button-disabled": {
    opacity: 0,
  },
}));
