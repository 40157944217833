import { useNavigate } from "react-router-dom";
import Tab, { TabProps } from "@mui/material/Tab";

type LinkTabPropsType = TabProps & {
  to?: string;
};
export const LinkTab = (props: LinkTabPropsType) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (props.to) {
      navigate(props.to);
    }
  };

  return (
    <Tab
      {...props}
      onClick={onClickHandler}
    />
  );
};
