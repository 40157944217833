import { BurgerIcon } from "@/assets/icons/BurgerIcon.tsx";
import { getStrapiMediaUrl } from "@/common";
import { Logo } from "@/layout/components/logo";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MobileHeaderDrawer } from "./MobileHeaderDrawer.tsx";
import { HeaderPropsType } from "../Header.tsx";

export const MobileHeader = (props: HeaderPropsType) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useLocation();
  useEffect(() => {
    setIsOpen(false);
  }, [navigate.pathname]);

  return (
    <Stack>
      <Stack
        sx={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 2,
          gap: 2,
          mx: 2,
          ...(!props.isHomepage
            ? {}
            : {
                position: "fixed",
                right: 20,
                left: 20,
                top: 0,
                mx: 0,
                zIndex: 10,
              }),
        }}
      >
        <Box>
          <Logo
            textColor={props.color}
            logoImg={getStrapiMediaUrl(props.logoImg)}
            logoText={props.logoText}
            logoLink={props.logoLink}
            isTextHidden
          />
        </Box>
        <Box
          onClick={() => setIsOpen(true)}
          sx={{
            bgcolor: props.isHomepage ? "white.084" : "black.004",
            borderRadius: 50,
            width: "min-content",
            p: 1.2,
          }}
        >
          <IconButton>
            <BurgerIcon />
          </IconButton>
        </Box>
      </Stack>
      <MobileHeaderDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        {...props}
      />
    </Stack>
  );
};
