import Box from "@mui/material/Box";
import { ReactNode } from "react";

type ShadowBoxPropsType = {
  children: ReactNode;
};
export const ShadowBox = ({ children }: ShadowBoxPropsType) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"absolute"}
      top={0}
      bottom={0}
      right={0}
      left={0}
      bgcolor={"black.032"}
      sx={{
        transition: "0.4s",
        opacity: 0,
        "&:hover": { opacity: 1 },
      }}
    >
      {children}
    </Box>
  );
};
