import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/store";

export type TAppState = {
  isHeaderHidden: boolean;
};

const slice = createSlice({
  name: "app",
  initialState: {
    isHeaderHidden: false,
  } as TAppState,
  reducers: {
    setIsHeaderHidden(state, action: PayloadAction<boolean>) {
      state.isHeaderHidden = action.payload;
    },
  },
});

export const selectIsHeaderHidden = (state: RootState) => state.app.isHeaderHidden;

export const appReducer = slice.reducer;
export const { setIsHeaderHidden } = slice.actions;
