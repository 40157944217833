import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { articlesApi } from "@/app/api/articles.ts";
import { LibraryItemType } from "@/modules/library/types";

export const getLibraryThunk = createAsyncThunk("library/get", async (params: { search?: string }, { dispatch }) => {
  const library = await articlesApi.getArticles(params);
  if (library) {
    dispatch(setLibrary(library));
  }
});

export type LibraryStateType = {
  articles: LibraryItemType[] | null;
  books: LibraryItemType[] | null;
};

const slice = createSlice({
  name: "library",
  initialState: {
    articles: null,
    books: null,
  } as LibraryStateType,
  reducers: {
    setLibrary(state, action: PayloadAction<LibraryItemType[]>) {
      state.books = action.payload.filter((el) => el.attributes.type === "книги");
      state.articles = action.payload.filter((el) => el.attributes.type === "статьи");
    },
  },
});

export const selectArticles = (state: RootState) => state.articles.articles;
export const selectBooks = (state: RootState) => state.articles.books;

export const libraryReducer = slice.reducer;
export const { setLibrary } = slice.actions;
