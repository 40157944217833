import { useAppController } from "@/app/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useGalleryController = () => {
  const [isMouseMove, setIsMouseMove] = useState(false);
  const [isSwiperTouch, setIsSwiperTouch] = useState(false);
  const [isNavigationVisible, setIsNavigationVisible] = useState(false);
  const [isMouseOnNavigation, setIsMouseOnNavigation] = useState(false);

  const { onHeaderShow, onHeaderHide } = useAppController();

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const cachedIsMouseMove = useMemo(() => isMouseMove, [isMouseMove]);

  const checkIsMouseOnHeader = useCallback((pageY: number) => {
    const screenHeight = window.innerHeight;

    if (pageY < 110 || screenHeight - pageY < 110) {
      setIsMouseOnNavigation(true);
    } else {
      setIsMouseOnNavigation(false);
    }
  }, []);

  const onMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      checkIsMouseOnHeader(e.pageY);

      if (isMouseOnNavigation) return;

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      setIsMouseMove(true);

      timeoutIdRef.current = setTimeout(() => {
        setIsMouseMove(false);
      }, 2000);
    },
    [checkIsMouseOnHeader, isMouseOnNavigation],
  );

  const onSwiperTouchStart = useCallback(() => {
    setIsSwiperTouch(true);
  }, []);

  const onSwiperTouchEnd = useCallback(() => {
    setIsSwiperTouch(false);
  }, []);

  useEffect(() => {
    if (cachedIsMouseMove || isMouseOnNavigation || isSwiperTouch) {
      onHeaderShow();
      setIsNavigationVisible(true);
    } else {
      setIsNavigationVisible(false);
      onHeaderHide();
    }
  }, [cachedIsMouseMove, isMouseOnNavigation, isSwiperTouch]);

  return {
    isMouseMove,
    onSwiperTouchStart,
    onSwiperTouchEnd,
    isNavigationVisible,
    onMouseMove,
  };
};
