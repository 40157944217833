import { useGalleryController } from "@/components/FullscreenGallery/useGalleryController.ts";
import { GallerySwiper } from "@/components/GallerySwiper";
import { MonkeyDataType } from "@/modules/monkeys";
import Box from "@mui/material/Box";

type FullScreenGalleryPropsType = {
  images?: MonkeyDataType[];
};

export const FullScreenGallery = (props: FullScreenGalleryPropsType) => {
  const {
    onMouseMove,
    // isNavigationVisible,
    onSwiperTouchStart,
    onSwiperTouchEnd,
  } = useGalleryController();

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      height={1}
      width={1}
      onMouseMove={onMouseMove}
    >
      <GallerySwiper
        onTouchStart={onSwiperTouchStart}
        onTouchEnd={onSwiperTouchEnd}
        isNavigationVisible={true}
        images={props.images}
        isNavigationFixed
      />
    </Box>
  );
};
