import { StrapiFileType, StrapiMediaType } from "@/common";

const databaseUrl = process.env.REACT_APP_DATABASE_URL;

export const getStrapiMediaUrl = (media?: StrapiMediaType | StrapiFileType) => {
  if (!databaseUrl) {
    return;
  }

  const path = media?.data?.attributes.url;
  return databaseUrl + path;
};
