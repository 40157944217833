import Stack, { StackProps } from "@mui/material/Stack";
import { ReactNode } from "react";
import Fade from "@mui/material/Fade";

type AppFadePropsType = StackProps & {
  index?: number;
  children: ReactNode;
  isVisible?: boolean;
};
export const AppFade = ({ index = 1, children, isVisible, ...stackProps }: AppFadePropsType) => {
  return (
    <Fade
      in={isVisible || true}
      timeout={500}
      style={{ transitionDelay: index * 100 + "ms" }}
    >
      <Stack {...stackProps}>{children}</Stack>
    </Fade>
  );
};
