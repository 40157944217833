import Typography, { TypographyProps } from "@mui/material/Typography";
import { ReactNode } from "react";
import Markdown from "react-markdown";

type AppTypographyPropsType = TypographyProps & {
  children: ReactNode;
  lineClamp?: number;
  opacity?: React.CSSProperties["opacity"];
};
export const AppTypography = ({ children, lineClamp, opacity, ...typographyProps }: AppTypographyPropsType) => {
  return (
    <Typography
      component={Markdown}
      fontSize={typographyProps.variant ? undefined : { xs: "0.9rem", md: "1rem" }}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: lineClamp || "initial",
        WebkitBoxOrient: "vertical",
        opacity: opacity,
        a: {
          color: "primary.main",
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "none",
          },
        },
        "& > p:not(p:last-of-type)": {
          mb: 3,
        },
        li: {
          listStyleType: "circle",
          ml: { xs: 2, md: 4 },
          mb: { xs: 1, md: 2 },
        },
      }}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};
