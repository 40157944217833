import { css, Theme } from "@mui/material/styles";

export const globalStyles = (theme: Theme) =>
  css({
    "*": {
      boxSizing: "border-box",
      padding: 0,
      margin: 0,
    },
    a: {
      color: "inherit",
      textDecoration: "none",
    },
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      margin: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightLight,
      overflowX: "hidden",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    code: {
      fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
    },
    ".stylized-scrollbar": {
      "::-webkit-scrollbar-button": {
        backgroundRepeat: "no-repeat",
        width: 6,
        height: 0,
      },

      "::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },

      "::-webkit-scrollbar-thumb": {
        webkitBorderRadius: 10,
        borderRadius: 10,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        zIndex: 100,
      },

      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      },

      "::-webkit-resizer": {
        backgroundRepeat: "no-repeat",
        width: 0,
        height: 0,
      },

      "::-webkit-scrollbar": {
        width: 8,
        height: 8,
      },
    },
  });
