import Container, { ContainerProps } from "@mui/material/Container";

type AppContainerPropsType = ContainerProps;

export const AppContainer = ({ children, sx, ...props }: AppContainerPropsType) => {
  return (
    <Container
      maxWidth={"xl"}
      sx={{
        px: { md: 5 },
        py: { xs: 2, sm: 1 },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Container>
  );
};
