import { monkeysApi } from "@/app/api/monkeys";
import { MonkeyInfoResponse } from "@/common/types/monkeys";
import { getNumForString } from "@/common/utils/getNumForString.ts";
import { RootState } from "@/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MonkeyType } from "../types";

export const getMonkeyImagesThunk = createAsyncThunk("monkeys/get", async (_params, { dispatch }) => {
  const res = await monkeysApi.getMonkeyImages();
  const monkeys = res?.map((el) => ({
    image: el.attributes.image.data.attributes.url,
  }));

  if (monkeys) {
    dispatch(setMonkeys(monkeys));
  }
});

export const getMonkeyInfosThunk = createAsyncThunk("monkey-infos/get", async (_params, { dispatch }) => {
  const res = await monkeysApi.getMonkeyInfos();

  const sortedMonkeys = res?.sort((a, b) => getNumForString(b.attributes.count) - getNumForString(a.attributes.count));

  if (sortedMonkeys) {
    dispatch(setMonkeyInfos(sortedMonkeys));
  }
});

export type TMonkeysState = {
  monkeys: MonkeyType[];
  monkeyInfos: MonkeyInfoResponse[];
};

const slice = createSlice({
  name: "monkeys",
  initialState: {
    monkeys: [],
    monkeyInfos: [],
  } as TMonkeysState,
  reducers: {
    setMonkeys(state, action: PayloadAction<MonkeyType[]>) {
      state.monkeys = action.payload;
    },
    setMonkeyInfos(state, action: PayloadAction<MonkeyInfoResponse[]>) {
      state.monkeyInfos = action.payload;
    },
  },
});

export const selectMonkeys = (state: RootState) => state.monkeys.monkeys;
export const selectMonkeyInfos = (state: RootState) => state.monkeys.monkeyInfos;

export const monkeysReducer = slice.reducer;
export const { setMonkeys, setMonkeyInfos } = slice.actions;
