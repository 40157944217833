import { getStrapiMediaUrl } from "@/common";
import { AppContainer } from "@/components/AppContainer";
import { AppFade } from "@/components/AppFade";
import { LinkTab } from "@/components/LinkTab";
import { Logo } from "@/layout/components/logo";
import { router } from "@/lib/router";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { HeaderPropsType } from "../index.ts";

export const DefaultHeader = (props: HeaderPropsType) => {
  const currentTab = useMemo(() => {
    const section = props.currentPage.split("/")[1];
    return Object.values(router).find((el: string) => el.includes(section));
  }, [props.currentPage]);

  return (
    <AppContainer
      sx={{
        py: { xs: 1, sm: 2 },
        pb: { xs: 0, sm: 2 },
      }}
    >
      <Stack
        sx={{
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          height: { xs: "auto", md: 72 },
          gap: 2,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            zIndex: 1,
          }}
        >
          <Logo
            logoImg={getStrapiMediaUrl(props.logoImg)}
            logoText={props.logoText}
            isTextHidden={props.isHidden}
            textColor={props.color}
            logoLink={props.logoLink}
            withShadow={props.isHidden}
          />
        </Stack>
        <AppFade isVisible={!props.isHidden}>
          <Typography
            component={NavLink}
            to={"/"}
            color={props.color}
            maxWidth={{ xs: 120, md: 120, lg: 280 }}
            textTransform={"uppercase"}
            fontFamily={'"Museo Sans Cyrl", sans-serif'}
            lineHeight={"120%"}
            fontSize={{ xs: 10, sm: 16 }}
            zIndex={1}
          >
            {props.text}
          </Typography>
        </AppFade>
        <AppFade
          isVisible={!props.isHidden}
          overflow={"hidden"}
        >
          <Tabs
            sx={{ display: { xs: "none", md: "flex" }, overflow: "hidden" }}
            variant={"scrollable"}
            value={currentTab}
            textColor={props.themeColor}
            indicatorColor={props.themeColor}
          >
            {props.sections?.map((el) => (
              <LinkTab
                sx={{ ml: 2, color: props.sectionsColor }}
                key={el.id}
                value={el.attributes.slug}
                label={el.attributes.text}
                to={el.attributes.slug}
              />
            ))}
          </Tabs>
        </AppFade>
      </Stack>
    </AppContainer>
  );
};
