import { Image } from "@/components/Image";
import { MonkeyDataType } from "@/modules/monkeys";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperWrapperStyles } from "./styles.ts";

type GallerySwiperPropsType = {
  images?: MonkeyDataType[];
  isNavigationVisible?: boolean;
  onTouchStart: () => void;
  onTouchEnd: () => void;
  isNavigationFixed?: boolean;
};
export const GallerySwiper = (props: GallerySwiperPropsType) => {
  return (
    <SwiperWrapperStyles
      isNavigationFixed={props.isNavigationFixed}
      isNavigationVisible={props.isNavigationVisible}
    >
      <Swiper
        navigation
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        speed={3000}
        onTouchStart={props.onTouchStart}
        onTouchEnd={props.onTouchEnd}
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        autoplay={{ delay: 10000, disableOnInteraction: false }}
        slidesPerView={1}
        effect={"fade"}
        lazyPreloadPrevNext={4}
        autoHeight
      >
        {props.images?.map((el, i) => (
          <SwiperSlide
            key={i}
            style={{ height: "100vh", minHeight: "100vh" }}
          >
            <Image
              image={el.attributes.image}
              alt={"monkey"}
              width={"100%"}
              height={"100%"}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapperStyles>
  );
};
