import { headerApi } from "@/app/api";
import { StrapiMediaType } from "@/common";
import { SectionType } from "@/layout";
import { pageNames } from "@/lib/router";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { DefaultHeader } from "./default-header";
import { HomepageHeader } from "./homepage-header";
import { MobileHeader } from "./mobile-header";

const data = await headerApi.getHeader();

export type HeaderPropsType = {
  logoImg?: StrapiMediaType;
  themeColor?: "primary" | "secondary";
  logoText?: string;
  logoLink?: string;
  color?: string;
  sectionsColor?: string;
  text?: string;
  currentPage: keyof typeof pageNames;
  sections?: SectionType[];
  isHidden?: boolean;
  isHomepage?: boolean;
};
export const Header = () => {
  const theme = useTheme();
  const navigate = useLocation();
  const isWindowLessThanMedium = useMediaQuery(theme.breakpoints.down("md"));

  const isHomepage = useMemo(() => {
    return navigate.pathname === "/";
  }, [navigate.pathname]);

  const currentPage = useMemo(() => {
    return navigate.pathname as keyof typeof pageNames;
  }, [navigate.pathname]);

  const headerProps: HeaderPropsType = {
    currentPage,
    isHomepage: isHomepage,
    themeColor: "primary",
    text: data?.text,
    color: data?.textColor,
    logoText: data?.logoText,
    logoImg: data?.logoDark,
    logoLink: data?.logoLink,
    sections: data?.sections?.data,
    sectionsColor: "inherit",
    ...(isHomepage && {
      color: theme.palette.secondary.main,
      sectionsColor: "#fff",
      logoImg: data?.logoLight,
      themeColor: "secondary",
    }),
  };

  const HeaderComponent = useMemo((): ((props: HeaderPropsType) => JSX.Element) => {
    if (isWindowLessThanMedium) return MobileHeader;
    if (isHomepage) return HomepageHeader;

    return DefaultHeader;
  }, [isHomepage, isWindowLessThanMedium]);

  return <HeaderComponent {...headerProps} />;
};
