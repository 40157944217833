import { pagesApi } from "@/app/api";
import { FullScreenGallery } from "@/components/FullscreenGallery";
import Stack from "@mui/material/Stack";
import { Suspense } from "react";

const data = await pagesApi.getHomePageData();

export const HomePage = () => {
  return (
    <Stack
      alignItems={"center"}
      width={1}
      height={"100vh"}
      minHeight={"100vh"}
      position={"relative"}
      bgcolor={"#24281c"}
    >
      <Suspense fallback={null}>
        <FullScreenGallery images={data?.attributes.monkeys.data} />
      </Suspense>
    </Stack>
  );
};
