import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appReducer } from "@/app/slice";
import { monkeysReducer } from "@/modules/monkeys";
import { libraryReducer } from "@/modules/library/slice";

const rootReducer = combineReducers({
  app: appReducer,
  articles: libraryReducer,
  monkeys: monkeysReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
